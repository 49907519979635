import { useLoaderData } from "react-router-dom";

async function loadHtmlContent(params) {
  console.log("params: ", params)

  let filename = `content/${params.file_path}.html`
  console.log("filename: ", filename)
  
  let htmlContent = fetch(filename)
  .then(function(response) {
    return response.text()
  })
  .then(function(html) {
    return html
  })
  .catch(function (err) {
    // There was an error
    console.warn('Something went wrong: ', err);
  });

  return htmlContent
}

function createMarkup(html) {
  return {__html: html};
}

export async function loader({ params }) {
  let htmlContent = "<h1>Loading...</h1>"

  // Fetch html content
  htmlContent = await loadHtmlContent(params);
  return htmlContent
}

export default function Content() {
  // const [htmlContent, setHtmlContent] = useState([]);
  const htmlContent = useLoaderData()

  return (
    <>
      <div className="entry-content">
        <p><a className="return-button" href="/">↩</a></p>
        <div dangerouslySetInnerHTML={createMarkup(htmlContent)}></div>
      </div>
    </>
  );
}