import { Link } from "react-router-dom";

export default function Root() {
  return (
    <>
      <div className="landing_page">
        <h2 className="large">HypeHype</h2>
        <ul id="nav">
          <li>
            <ul className="lang_list">
              <li><Link to={`hypehype-tos-en`}>Terms of Service</Link></li>
              <li><Link to={`hypehype-privacy-policy-en`}>Privacy Policy</Link></li>
            </ul>
          </li>
        </ul>

        <h2 className="large">Other products</h2>
        <ul id="nav">
            <li><h3 className="no-margin">Terms of Service</h3>
            <ul className="lang_list">
                <li><Link to={`tos-en`}>English</Link></li>
                <li><Link to={`tos-fr`}>Français</Link></li>
                <li><Link to={`tos-de`}>Deutsch</Link></li>
                <li><Link to={`tos-es`}>Español</Link></li>
                <li><Link to={`tos-it`}>Italiano</Link></li>
                <li><Link to={`tos-pt`}>Português</Link></li>
                <li><Link to={`tos-tk`}>Türkçe</Link></li>
                <li><Link to={`tos-fi`}>Suomi</Link></li>
                <li><Link to={`tos-jp`}>日本語</Link></li>
                <li><Link to={`tos-ko`}>한국어</Link></li>
                <li><Link to={`tos-cn1`}>简体中文</Link></li>
                <li><Link to={`tos-cn2`}>繁體中文</Link></li>
                <li><Link to={`tos-ru`}>Русский</Link></li>
            </ul>
          </li>
            <li>
            <h3>Privacy Policy</h3>
            <ul className="lang_list">
                <li><Link to={`privacy-policy-en`}>English</Link></li>
                <li><Link to={`privacy-policy-fr`}>Français</Link></li>
                <li><Link to={`privacy-policy-de`}>Deutsch</Link></li>
                <li><Link to={`privacy-policy-es`}>Español</Link></li>
                <li><Link to={`privacy-policy-it`}>Italiano</Link></li>
                <li><Link to={`privacy-policy-pt`}>Português</Link></li>
                <li><Link to={`privacy-policy-tk`}>Türkçe</Link></li>
                <li><Link to={`privacy-policy-fi`}>Suomi</Link></li>
                <li><Link to={`privacy-policy-jp`}>日本語</Link></li>
                <li><Link to={`privacy-policy-ko`}>한국어</Link></li>
                <li><Link to={`privacy-policy-cn1`}>简体中文</Link></li>
                <li><Link to={`privacy-policy-cn2`}>繁體中文</Link></li>
                <li><Link to={`privacy-policy-ru`}>Русский</Link></li>
            </ul>
          </li>
        </ul>
      </div>
      {/* <nav>
          <ul>
            <li>
              <Link to={`terms-of-service`}>TOS</Link>
            </li>
            <li>
              <Link to={`world`}>World</Link>
            </li>
          </ul>
        </nav> */}
    </>
  );
}