import React from 'react';
import ReactDOM from 'react-dom/client';
import Root from "./root";
import Content, { loader as contentLoader } from "./content";

import './index.css';

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
  },
  {
    path: "/:file_path",
    element: <Content />,
    loader: contentLoader
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <div id="header">
      <img className="site-logo" src="hypehype.png" alt="HypeHype" />
    </div>

    <RouterProvider router={router} />

    <div id="footer"></div>
  </React.StrictMode>
);
